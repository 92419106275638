import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import Layout from '../../../../components/layout'
import Seo from '../../../../components/seo';
import Analytics from '../../../../utils/analytics';
import Validations from '../../../../utils/validations';
import Bootstrap from '../../../../utils/bootstrap';
import axios from 'axios';
import $ from 'jquery';
import './styles/main.scss';

const Page = (location) => {
  const [loading, setLoading] = useState(false);
  
  let [name, setName] = useState('');
  let [email, setEmail] = useState('');

  let [nameError, setNameError] = useState('');
  let [emailError, setEmailError] = useState('');

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === 'name') {
      setNameError('');
      setName(value);
    } else if (name === 'email') {
      setEmailError('');
      setEmail(value);
    }
  };

  const submitRequest = () => {
    setLoading(true);
    axios({
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      url: `https://api.analisedeacoes.com/lp/workshop`,
      data: {
        email: email,
        name: name,
      }
    })
    .then(result => {
      setLoading(false);
      Analytics.track('Workshop (25/10)', 'Inscrição', 'Inscrição realizada com sucesso');

      Bootstrap.hideModal('#workshop-register');
      navigate('/workshop/sucesso/');
    })
    .catch(() => {
      setLoading(false);
      Analytics.track('Workshop (25/10)', 'Inscrição', 'Inscrição realizada com sucesso, mas provavelmente o e-mail não foi enviado');

      Bootstrap.hideModal('#workshop-register');
      navigate('/workshop/sucesso/');
    });
  };

  const handleClickDownload = event => {
    Analytics.track('Workshop (25/10)', 'Modal lead', 'Abrir');
    Bootstrap.showModal('#workshop-register');
  };

  const handleClickSubmit = event => {
    let error = false;

    if (!(name.length > 3)) {
      setNameError('active');
      error = true;
    }

    if (!Validations.isValidEmail(email)) {
      setEmailError('active');
      error = true;
    }

    if (error) {
      return;
    }
    submitRequest();
  };

  const trackAnalytics = () => {
    $('#workshop .breadcrumb .gotohome').on('click', () => Analytics.track('Workshop (25/10)', 'Breadcrumb', 'Página inicial'));
    $(`#workshop #workshop-register .modal-header button`).on('click', () => Analytics.track('Workshop (25/10)', `Modal lead`, 'Fechar'));
    $(`#workshop #workshop-register .modal-footer .button-primary`).on('click', () => Analytics.track('Workshop (25/10)', `Modal lead`, `Continuar`));
  };

  useEffect(() => {
    trackAnalytics();
    // https://t.me/joinchat/CP6bWOZr4PY0MDYx
  }, [])

  return (
    <Layout page="workshop" location={location} name="workshop">
      <Seo
        title={`Workshop 25/10`}
        description="Workshop 25/10."
        url="/workshop/" />

      <section className="workshop">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-6">
              <img className="image" src="/images/helpers/workshop-analysis.jpg" height="399px" alt="Workshop" />
            </div>

            <div className="col-xs-12 col-md-6 col-lg-6">
              <p className="type">Workshop</p>
              <h1 className="title">Análise de Ações</h1>
              <p className="author">Express</p>
              <p className="description">Veja como ter retornos acima da média, tanto no Brasil quanto no exterior, analisando ações em menos de 30 minutos. <br/> <br/> Mesmo que você esteja começando do absoluto zero no mundo dos investimentos.</p>
              <button type="button" className={"button button-primary"} onClick={handleClickDownload}>Participar gratuitamente</button>
            </div>
          </div>
        </div>
    
        <div id="workshop-register" class="modal modal-small">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

              <div class="modal-body">
                <p className="modal-title">A confirmação será enviada para o seu e-mail.</p>
                
                <div className="row">
                  <div className="input col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <input type="text" className="form-control" placeholder="Nome *" name="name" value={name} onChange={handleInputChange} maxLength={50} required />
                    { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                    <label className={`error ${nameError}`}>Verifique o nome digitado e tente novamente.</label>
                  </div>

                  <div className="input col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <input type="text" className="form-control" placeholder="E-mail *" name="email" value={email} maxLength={80} onChange={handleInputChange} required />
                    { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                    <label className={`error ${emailError}`}>Verifique o e-mail digitado e tente novamente.</label>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="button button-primary" onClick={handleClickSubmit} disabled={loading}>{`${loading ? 'Carregando' : 'Continuar'}`}</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
