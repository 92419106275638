let isValidEmail = (value) => {
  if (!(value && value.length > 3 && value.includes('@'))) {
    return false;
  }

  const username = value.substr(0, value.indexOf('@'));
  const domain = value.substr(value.indexOf('@') + 1);
  const usernameRegex = /^([\da-zA-Z]([-_\\.~!#]*[\da-zA-Z-_]+)*)$/;
  const domainRegex = /^(([A-Za-z\d]{1,})(([-]{0,1}[A-Za-z\d])+\.))+([A-Za-z\d]{2,20})$/;

  if (!usernameRegex.test(username) || !domainRegex.test(domain)) {
    return false;
  }

  const domainArray = domain.split('.');
  const domainGmailPercentage = similarity('gmail', domainArray[0]);
  const domainHotmailPercentage = similarity('hotmail', domainArray[0]);
  const domainYahooPercentage = similarity('yahoo', domainArray[0]);

  switch (domainArray[0]) {
    case 'gmail':
      if (domainArray.length !== 2) {
        return false;
      }
      
      if (domainArray[1] !== 'com') {
        return false;
      }
      return true;
    case 'hotmail':
      if (domainArray.length < 2) {
        return false;
      }
      
      if (domainArray.length > 1) {
        if (domainArray[1] !== 'com') {
          return false;
        }
      }
      
      if (domainArray.length > 2) {
        if (domainArray[2] !== 'br') {
          return false;
        }
      }
      return true;
    case 'yahoo':
      if (domainArray.length < 2) {
        return false;
      }
      
      if (domainArray.length > 1) {
        if (domainArray[1] !== 'com') {
          return false;
        }
      }
      
      if (domainArray.length > 2) {
        if (domainArray[2] !== 'br') {
          return false;
        }
      }
      return true;
    case 'bol':
    case 'uol':
      if (domainArray.length !== 3) {
        return false;
      }
      
      if (domainArray[1] !== 'com') {
        return false;
      }
      
      if (domainArray[2] !== 'br') {
        return false;
      }
      return true;
    case 'ymail':
      if (domainArray.length !== 2) {
        return false;
      }
      
      if (domainArray[1] !== 'com') {
        return false;
      }
      return true;
    case 'emailtemporario':
    case 'lenfly':
    case 'meidir':
    case 'weepm':
    case 'mozej':
    case 'mailna':
    case 'mohmal':
    case 'uorak':
    case 'forexnews':
    case 'swimminggkm':
    case 'guerrillamail':
    case 'guerrillamailblock':
    case 'pokemail':
    case 'spam4':
    case 'sharklasers':
    case 'nyfeel':
    case 'catdogmail':
      return false;
    default:
  }

  if (domainGmailPercentage > 70 ||
      domainHotmailPercentage > 70 || 
      domainYahooPercentage > 70) {
        return false;
  }
  return true;
}

let isValidPhone = (value) => {
  if (!value || !(/^\d+$/).test(value) || (value.length > 9 || value.length < 8)) {
    return false;
  }

  if (value.length === 9) {
    if ((/^0|^1|^2|^3|^4|^5/).test(value) ||
        (/(0{5}|1{5}|2{5}|3{5}|4{5}|5{5}|6{5}|7{5}|8{5}|9{5})/).test(value) ||
        (/(01234)|(12345)|(23456)|(34567)|(45678)|(56789)/).test(value)) {
      return false;
    }
  } else {
    if ((/(0{5}|1{5}|2{5}|3{5}|4{5}|5{5}|6{5}|7{5}|8{5}|9{5})/).test(value) ||
        (/(01234)|(12345)|(23456)|(34567)|(45678)|(56789)/).test(value)) {
      return false;
    }
  }

  const listOfChars = Array.from(new Set(value.split('')));
  if (listOfChars.length < 4) {
    return false;
  }
  return true;
}

let isValidCpf = (value) => {
  if (!value || value.length !== 11) {
    return false;
  }

  // verifica se não é 11111111111 etc
  if (!Array.from(value).filter(char => char !== value[0]).length) {
    return false
  }

  let sum = 0;
  let rest;

  for (let index = 1; index <= 9; index++) {
    sum = sum + parseInt(value.substring(index-1, index)) * (11 - index);
  }

  rest = (sum * 10) % 11;
  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  }


  if (rest !== parseInt(value.substring(9, 10))) {
    return false
  }

  sum = 0;
  for (let index = 1; index <= 10; index++) {
    sum = sum + parseInt(value.substring(index - 1, index)) * (12 - index);
  }

  rest = (sum * 10) % 11;
  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  }

  if (rest !== parseInt(value.substring(10, 11))) {
    return false;
  }

  return true;
}

let similarity = (s1, s2) => {
  let editDistance = (s1, s2) => {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();
  
    let costs = [];
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j++) {
        if (i === 0) {
          costs[j] = j;
        }

        else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            }

            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
  
      if (i > 0) {
        costs[s2.length] = lastValue;
      }
    }
    return costs[s2.length];
  }

  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  let longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0 * 100;
  }
  return ((longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)) * 100;
}

module.exports = { isValidEmail, isValidPhone, isValidCpf };
